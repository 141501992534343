import { SurveyModule } from "@cartographerio/inventory-surveys";
import { MapSchema } from "@cartographerio/topo-map";
import {
  ArcgisIntegration,
  Invitation,
  InvitationCode,
  Project,
  Survey,
  Team,
  User,
  Workspace,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";

import { useApiServerId } from "../contexts/apiConfig";
import { routes } from "../routes";
import { TOPBAR_HEIGHT } from "./PageLayout/base";
import ProjectLabel from "./ProjectLabel";
import ServerPill from "./ServerPill";
import { projectMapLabel, projectSurveyModuleLabel } from "./Sidebar/base";

interface BreadcrumbItemData {
  label: ReactNode;
  flex?: boolean; // flexible width?
  to?: string;
}

export type InvitationTabOption = "single" | "code";
export type ProjectTransferTabOption = "survey" | "project";

export interface BreadcrumbItemProps {
  basePage?:
    | "account"
    | "workspaces"
    | `invitation-${InvitationTabOption}`
    | "members"
    | "transfer-user"
    | "rca-setup";
  workspace?: Workspace | "new";
  workspacePage?:
    | "settings"
    | "billing"
    | "projects"
    | "teams"
    | "members"
    | `invitation-${InvitationTabOption}`;
  project?: Project | "new";
  projectPage?:
    | "settings"
    | "integrations"
    | "members"
    | "teams"
    | `invitation-${InvitationTabOption}`
    | `transfer-${ProjectTransferTabOption}`;
  integration?: "arcgis";
  arcgisIntegration?: ArcgisIntegration | "new";
  module?: SurveyModule;
  survey?: Survey | "new";
  map?: MapSchema;
  team?: Team | "new";
  teamPage?: "settings" | "members" | `invitation-${InvitationTabOption}`;
  user?: User | "new";
  invitation?: Invitation | "new";
  invitationCode?: InvitationCode | "new";
}

function standardSiteBreadcrumb(
  props: BreadcrumbItemProps
): BreadcrumbItemData[] {
  const {
    basePage,
    workspace,
    workspacePage,
    project,
    projectPage,
    integration,
    arcgisIntegration,
    module,
    survey,
    map,
    team,
    teamPage,
    user,
    invitation,
    invitationCode,
  } = props;

  const passedWorkspace = workspace != null && workspace !== "new";
  const passedProject = project != null && project !== "new";
  const passedTeam = team != null && team !== "new";

  const items: BreadcrumbItemData[] = [];

  if (basePage != null) {
    switch (basePage) {
      case "account":
        items.push({
          label: "Account",
          to: routes.account.url([]),
        });
        break;
      // Admin site only
      case "invitation-single":
      case "invitation-code":
      case "members":
      case "transfer-user":
      case "rca-setup":
      case "workspaces":
        break;
      default:
        return checkExhausted(basePage);
    }
  }

  if (passedWorkspace) {
    items.push({
      label: workspace.name,
      to: routes.workspace.home.url([workspace.alias]),
    });
  }

  if (workspacePage != null && passedWorkspace) {
    switch (workspacePage) {
      case "settings":
        items.push({
          label: "Settings",
          to: routes.workspace.settings.url([workspace.alias]),
        });
        break;
      case "billing":
        items.push({
          label: "Billing",
          to: routes.workspace.billing.settings.url([workspace.alias]),
        });
        break;
      case "projects":
        items.push({
          label: "Projects",
          to: routes.workspace.project.list.url([workspace.alias]),
        });
        break;
      case "teams":
        items.push({
          label: "Teams",
          to: routes.workspace.team.list.url([workspace.alias]),
        });
        break;
      case "members":
        items.push({
          label: "Members",
          to: routes.workspace.member.list.url([workspace.alias]),
        });
        break;
      case "invitation-single":
        items.push({
          label: "Invitations",
          to: routes.workspace.invitation.list.url([workspace.alias]),
        });
        break;
      case "invitation-code":
        items.push({
          label: "Invitation Codes",
          to: routes.workspace.invitation.code.list.url([workspace.alias]),
        });
        break;
      default:
        return checkExhausted(workspacePage);
    }
  }

  if (project != null && passedWorkspace) {
    if (project === "new") {
      items.push({
        label: "New",
        to: routes.workspace.project.create.url([workspace.alias]),
      });
    } else {
      items.push({
        label: <ProjectLabel project={project} />,
        to: routes.workspace.project.home.url([workspace.alias, project.alias]),
      });
    }
  }

  if (projectPage != null && passedProject && passedWorkspace) {
    switch (projectPage) {
      case "settings":
        items.push({
          label: "Settings",
          to: routes.workspace.project.settings.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "integrations":
        items.push({
          label: "Integrations",
          to: routes.workspace.project.integrations.list.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "members":
        items.push({
          label: "Members",
          to: routes.workspace.project.member.list.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "invitation-single":
        items.push({
          label: "Invitations",
          to: routes.workspace.project.invitation.list.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "invitation-code":
        items.push({
          label: "Invitation Codes",
          to: routes.workspace.project.invitation.code.list.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "teams":
        items.push({
          label: "Teams",
          to: routes.workspace.project.teams.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "transfer-survey":
        items.push({
          label: "Transfer Surveys",
          to: routes.workspace.project.transfer.survey.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      case "transfer-project":
        items.push({
          label: "Transfer Project",
          to: routes.workspace.project.transfer.project.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      default:
        return checkExhausted(projectPage);
    }
  }

  if (integration != null && passedWorkspace && passedProject) {
    switch (integration) {
      case "arcgis":
        items.push({
          label: "ArcGIS",
          to: routes.workspace.project.integrations.arcgis.list.url([
            workspace.alias,
            project.alias,
          ]),
        });
        break;
      default:
        return checkExhausted(integration);
    }
  }

  if (arcgisIntegration != null && passedWorkspace && passedProject) {
    if (arcgisIntegration === "new") {
      items.push({
        label: "New",
        to: routes.workspace.project.integrations.arcgis.create.url([
          workspace.alias,
          project.alias,
        ]),
      });
    } else {
      items.push({
        label: arcgisIntegration.name,
        to: routes.workspace.project.integrations.arcgis.update.url([
          workspace.alias,
          project.alias,
          arcgisIntegration.id,
        ]),
      });
    }
  }

  if (module != null && passedProject && passedWorkspace) {
    items.push({
      label: projectSurveyModuleLabel(project, module),
      to: routes.workspace.project.survey.list.url([
        workspace.alias,
        project.alias,
        module.moduleId,
      ]),
    });
  }

  if (survey != null && module != null && passedWorkspace && passedProject) {
    if (survey === "new") {
      items.push({
        label: "New",
        to: routes.workspace.project.survey.create.url([
          workspace.alias,
          project.alias,
          module.moduleId,
        ]),
      });
    } else {
      items.push({
        label: module
          .description(survey)
          .recover(() => "-")
          .unsafeGet(),
        flex: true,
        to: routes.workspace.project.survey.view.url([
          workspace.alias,
          project.alias,
          module.moduleId,
          survey.id,
        ]),
      });
    }
  }

  if (map != null && passedProject && passedWorkspace) {
    items.push({
      label: projectMapLabel(project, map),
      to: routes.workspace.project.map.url([
        workspace.alias,
        project.alias,
        map.mapId,
      ]),
    });
  }

  if (team != null && passedWorkspace) {
    if (team === "new") {
      items.push({
        label: "New",
        to: routes.workspace.team.create.url([workspace.alias]),
      });
    } else {
      items.push({
        label: team.name,
        to: routes.workspace.team.settings.url([workspace.alias, team.alias]),
      });
    }
  }

  if (teamPage != null && passedTeam && passedWorkspace) {
    switch (teamPage) {
      case "settings":
        items.push({
          label: "Settings",
          to: routes.workspace.team.settings.url([workspace.alias, team.alias]),
        });
        break;
      case "members":
        items.push({
          label: "Members",
          to: routes.workspace.team.member.list.url([
            workspace.alias,
            team.alias,
          ]),
        });
        break;
      case "invitation-single":
        items.push({
          label: "Invitations",
          to: routes.workspace.team.invitation.list.url([
            workspace.alias,
            team.alias,
          ]),
        });
        break;
      case "invitation-code":
        items.push({
          label: "Invitation Codes",
          to: routes.workspace.team.invitation.code.list.url([
            workspace.alias,
            team.alias,
          ]),
        });
        break;
      default:
        return checkExhausted(teamPage);
    }
  }

  if (user != null && passedWorkspace) {
    if (passedProject && user !== "new") {
      items.push({
        label: user.screenName,
        to: routes.workspace.project.member.update.url([
          workspace.alias,
          project.alias,
          user.id,
        ]),
      });
    } else if (user === "new") {
      items.push({
        label: "New",
        to: routes.workspace.member.create.url([workspace.alias]),
      });
    } else {
      items.push({
        label: user.screenName,
        to: routes.workspace.member.update.url([workspace.alias, user.id]),
      });
    }
  }

  if (invitation != null && passedWorkspace) {
    if (invitation === "new") {
      items.push({
        label: "New",
        to: routes.workspace.invitation.create.url([workspace.alias]),
      });
    } else if (passedTeam) {
      items.push({
        label: `${invitation.firstName} ${invitation.lastName}`,
        to: routes.workspace.team.invitation.view.url([
          workspace.alias,
          team.alias,
          invitation.id,
        ]),
      });
    } else if (passedProject) {
      items.push({
        label: `${invitation.firstName} ${invitation.lastName}`,
        to: routes.workspace.project.invitation.view.url([
          workspace.alias,
          project.alias,
          invitation.id,
        ]),
      });
    } else {
      items.push({
        label: `${invitation.firstName} ${invitation.lastName}`,
        to: routes.workspace.invitation.view.url([
          workspace.alias,
          invitation.id,
        ]),
      });
    }
  }

  if (invitationCode != null && passedWorkspace) {
    if (invitationCode === "new") {
      items.push({
        label: "New",
        to: routes.workspace.invitation.code.create.url([workspace.alias]),
      });
    } else if (passedTeam) {
      items.push({
        label: invitationCode.name,
        to: routes.workspace.team.invitation.code.view.url([
          workspace.alias,
          team.alias,
          invitationCode.alias,
        ]),
      });
    } else if (passedProject) {
      items.push({
        label: invitationCode.name,
        to: routes.workspace.project.invitation.code.view.url([
          workspace.alias,
          project.alias,
          invitationCode.alias,
        ]),
      });
    } else {
      items.push({
        label: invitationCode.name,
        to: routes.workspace.invitation.code.view.url([
          workspace.alias,
          invitationCode.alias,
        ]),
      });
    }
  }

  return items;
}

function adminSiteBreadcrumb(props: BreadcrumbItemProps): BreadcrumbItemData[] {
  const { basePage, workspace, user, invitation, invitationCode } = props;

  const items: BreadcrumbItemData[] = [
    { to: routes.admin.home.url([]), label: "Admin" },
  ];

  if (basePage != null) {
    switch (basePage) {
      case "invitation-single":
        items.push({
          to: routes.admin.invitation.list.url([]),
          label: "Invitations",
        });
        break;
      case "invitation-code":
        items.push({
          to: routes.admin.invitation.code.list.url([]),
          label: "Invitation Codes",
        });
        break;
      case "members":
        items.push({
          to: routes.admin.member.list.url([]),
          label: "Members",
        });
        break;
      case "workspaces":
        items.push({
          to: routes.admin.workspace.list.url([]),
          label: "Workspaces",
        });
        break;
      case "transfer-user":
        items.push({
          to: routes.admin.transfer.user.url([]),
          label: "Transfer User",
        });
        break;
      case "rca-setup":
        items.push({
          to: routes.admin.rcaSetup.url([]),
          label: "RCA Trainees Setup",
        });
        break;
      case "account":
        break;
      default:
        return checkExhausted(basePage);
    }
  }

  if (workspace != null) {
    items.push({ label: "New", to: routes.admin.workspace.create.url([]) });
  }

  if (user != null) {
    if (user === "new") {
      items.push({
        label: "New",
        to: routes.admin.member.create.url([]),
      });
    } else {
      items.push({
        label: user.screenName,
        to: routes.admin.member.view.url([user.id]),
      });
    }
  }

  if (invitation != null) {
    if (invitation === "new") {
      items.push({
        label: "New",
        to: routes.admin.invitation.create.url([]),
      });
    } else {
      items.push({
        label: `${invitation.firstName} ${invitation.lastName}`,
        to: routes.admin.invitation.view.url([invitation.id]),
      });
    }
  }

  if (invitationCode != null) {
    if (invitationCode === "new") {
      items.push({
        label: "New",
        to: routes.admin.invitation.code.create.url([]),
      });
    } else {
      items.push({
        label: invitationCode.name,
        to: routes.admin.invitation.code.view.url([invitationCode.alias]),
      });
    }
  }

  return items;
}

export interface PageBreadcrumbProps extends BreadcrumbItemProps {
  admin?: boolean;
}

export default function PageBreadcrumb(
  props: PageBreadcrumbProps
): ReactElement {
  const { admin } = props;

  const [server] = useApiServerId();

  const items = useMemo(
    () => (admin ? adminSiteBreadcrumb(props) : standardSiteBreadcrumb(props)),
    [admin, props]
  );

  return (
    <HStack
      alignItems="center"
      spacing="4"
      minH={`${TOPBAR_HEIGHT}px`}
      zIndex="inherit"
      pl="12"
      pr="4"
      py="2"
      top="0"
      left="0"
      right="0"
      w="100%"
      flexGrow="1"
      flexWrap="wrap"
    >
      <Breadcrumb separator="/" listProps={{ flexWrap: "wrap" }} minW="0">
        {server !== "production" && (
          <BreadcrumbItem>
            <ServerPill server={server} />
          </BreadcrumbItem>
        )}
        {items.map((item, i) => (
          <BreadcrumbItem
            key={i}
            flexGrow={0}
            flexShrink={item.flex ? 1 : 0}
            minW="0"
          >
            <BreadcrumbLink
              as={Link}
              to={item.to ?? "."}
              isCurrentPage={i === items.length - 1}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {item.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </HStack>
  );
}
