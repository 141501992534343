import { route } from "../../routes";
import * as path from "./pathParams";
import * as query from "./queryParams";

export const home = route();

export const login = route("login").withQuery({
  go: query.string,
  code: query.invitationCodeAlias,
});

export const signin = route("signin").withQuery({
  go: query.string,
});

export const signinWithCode = route(
  "signin",
  path.invitationCodeAlias
).withQuery({
  go: query.string,
});

export const logout = route("logout").withQuery({ go: query.string });
export const signout = route("signout").withQuery({ go: query.string });

export const password = {
  reset: route("password", "reset").withQuery({ token: query.string }),
  forgot: route("password", "forgot").withQuery({ email: query.string }),
};

export const qualification = {
  register: route(
    "qualification",
    "register",
    path.qualificationAlias
  ).withQuery({ q: query.string }),
  certificate: route(
    "qualification",
    "certificate",
    path.registerEntryId
  ).withQuery({ view: query.certificateView }),
};

export const signup = route("signup").withQuery({
  go: query.string,
  code: query.invitationCodeAlias,
});

export const account = route("account");

export const identity = {
  change: route("change-identity", path.userId).withQuery({ go: query.string }),
  restore: route("restore-identity").withQuery({ go: query.string }),
};

export const invitation = {
  accept: route("invitation", path.invitationId, "accept"),
};

export const embed = {
  map: route("embed", "map").withQuery({
    server: query.apiServerId,
    authorization: query.accessToken,
    map: query.mapId,
    attribute: query.mapAttribute,
    base: query.mapBase,
    inspector: query.mapInspector,
    workspace: query.workspaceRef,
    project: query.projectRef,
    team: query.teamRef,
    scrollwheel: query.boolean,
    center: query.point,
    zoom: query.number,
    when: query.namedInterval,
    survey: query.surveyId,
    uiembed: query.boolean,
  }),
  attachment: {
    view: route("embed", "attachment").withQuery({
      server: query.apiServerId,
      authorization: query.accessToken,
      attachment: query.attachmentId,
    }),
    folder: route("embed", "attachment", "folder").withQuery({
      server: query.apiServerId,
      authorization: query.accessToken,
      survey: query.surveyId,
      folder: query.attachmentFolder,
    }),
  },
};

export const attachment = {
  view: route("attachment", path.attachmentId),
  folder: route("attachment", "folder", path.surveyId, path.attachmentFolder),
};

export const short = {
  invitation: route("invitation", path.invitationId),
  invitationCode: {
    view: route("invitation-code", path.invitationCodeRef),
    signup: {
      approve: route(
        "invitation-code",
        path.invitationCodeRef,
        "signup",
        "approve",
        path.userIdOrEmail
      ),
      reject: route(
        "invitation-code",
        path.invitationCodeRef,
        "signup",
        "reject",
        path.userIdOrEmail
      ),
    },
  },
  project: route("project", path.projectId),
  survey: route("survey", path.surveyId).withQuery({ page: query.pageIndex }),
  team: route("team", path.teamId),
  workspace: route("workspace", path.workspaceRef),
  billing: route("billing"),
};

export const dev = {
  mapboxViewer: route("dev", "mapbox"),
  frontPlugin: route("dev", "frontapp"),
};

// prettier-ignore
export const workspace = {
  home: route("ws", path.workspaceRef),
  holding: route("ws", path.workspaceRef, "holding"),
  settings: route("ws", path.workspaceRef, "settings"),
  billing: {
    settings: route("ws", path.workspaceRef, "billing").withQuery({
      checkout: query.stripeCheckoutResult,
    }),
    checkout: route("ws", path.workspaceRef, "billing", "checkout").withQuery({
      plan: query.planId,
      interval: query.billingInterval
    }),
    dashboard: route("ws", path.workspaceRef, "billing", "dashboard"),
    portal: route("ws", path.workspaceRef, "billing", "portal"),
  },
  project: {
    list: route("ws", path.workspaceRef, "projects").withQuery(
      query.projectList,
      { cache: true }
    ),
    create: route("ws", path.workspaceRef, "projects", "new"),
    home: route("ws", path.workspaceRef, "projects", path.projectRef),
    settings: route(
      "ws",
      path.workspaceRef,
      "projects",
      path.projectRef,
      "settings"
    ),
    integrations: {
      list: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "integrations"
      ),
      arcgis: {
        list: route(
          "ws",
          path.workspaceRef,
          "projects",
          path.projectRef,
          "integrations",
          "arcgis"
        ).withQuery(query.arcgisIntegrationList, { cache: true }),
        create: route(
          "ws",
          path.workspaceRef,
          "projects",
          path.projectRef,
          "integrations",
          "arcgis",
          "new"
        ),
        update: route(
          "ws",
          path.workspaceRef,
          "projects",
          path.projectRef,
          "integrations",
          "arcgis",
          path.arcgisIntegrationId
        ),
        authorize: route(
          "ws",
          path.workspaceRef,
          "projects",
          path.projectRef,
          "integrations",
          "arcgis",
          path.arcgisIntegrationId,
          "authorise"
        ).withQuery({ code: query.arcgisAuthCode }),
      },
    },
    teams: route(
      "ws",
      path.workspaceRef,
      "projects",
      path.projectRef,
      "teams"
    ).withQuery(query.teamList, { cache: true }),
    transfer: {
      survey: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "transfer",
        "survey"
      ),
      project: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "transfer",
        "project"
      ),
    },
    member: {
      list: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "members"
      ).withQuery(query.projectMemberList, { cache: true }),
      update: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "members",
        path.userId
      ),
    },
    invitation: {
      list: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "invitations"
      ).withQuery(query.projectInvitationList, { cache: true }),
      view: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "invitations",
        path.invitationId
      ),
      code: {
        list: route(
          "ws",
          path.workspaceRef,
          "projects",
          path.projectRef,
          "invitation-codes"
        ).withQuery(query.projectInvitationCodeList, { cache: true }),
        view: route(
          "ws",
          path.workspaceRef,
          "projects",
          path.projectRef,
          "invitation-codes",
          path.invitationAlias
        ),
      },
    },
    survey: {
      list: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "surveys",
        path.moduleId
      ).withQuery(query.surveyList, { cache: true }),
      create: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "surveys",
        path.moduleId,
        "new"
      ).withQuery({
        page: query.pageIndex,
        team: query.teamRef,
        template: query.surveyId,
      }),
      view: route(
        "ws",
        path.workspaceRef,
        "projects",
        path.projectRef,
        "surveys",
        path.moduleId,
        path.surveyId
      ).withQuery({ page: query.pageIndex }),
    },
    map: route(
      "ws",
      path.workspaceRef,
      "projects",
      path.projectRef,
      "map",
      path.mapId
    ).withQuery(
      {
        base: query.mapBase,
        inspector: query.mapInspector,
        attribute: query.mapAttribute,
        when: query.namedInterval,
        scrollwheel: query.boolean,
        survey: query.surveyId,
        team: query.teamRef,
        center: query.point,
        zoom: query.fixedPrecisionNumber(2),
      },
      { cache: true }
    ),
    catchAll: route(
      "ws",
      path.workspaceRef,
      "projects",
      path.projectRef,
      path.rest
    ),
  },
  team: {
    list: route("ws", path.workspaceRef, "teams").withQuery(
      query.workspaceTeamList,
      { cache: true }
    ),
    create: route("ws", path.workspaceRef, "teams", "new").withQuery({
      project: query.projectRef,
    }),
    settings: route("ws", path.workspaceRef, "teams", path.teamRef),
    member: {
      list: route(
        "ws",
        path.workspaceRef,
        "teams",
        path.teamRef,
        "members"
      ).withQuery(query.teamMemberList, { cache: true }),
      update: route(
        "ws",
        path.workspaceRef,
        "teams",
        path.teamRef,
        "members",
        path.userId
      ),
    },
    invitation: {
      list: route(
        "ws",
        path.workspaceRef,
        "teams",
        path.teamRef,
        "invitations"
      ).withQuery(query.teamInvitationList, { cache: true }),
      view: route(
        "ws",
        path.workspaceRef,
        "teams",
        path.teamRef,
        "invitations",
        path.invitationId
      ),
      code: {
        list: route(
          "ws",
          path.workspaceRef,
          "teams",
          path.teamRef,
          "invitation-codes"
        ).withQuery(query.teamInvitationCodeList, { cache: true }),
        view: route(
          "ws",
          path.workspaceRef,
          "teams",
          path.teamRef,
          "invitation-codes",
          path.invitationAlias
        ),
      },
    },
  },
  member: {
    list: route("ws", path.workspaceRef, "members").withQuery(
      query.workspaceMemberList,
      { cache: true }
    ),
    create: route("ws", path.workspaceRef, "members", "new").withQuery({
      project: query.projectRef,
      team: query.teamRef,
    }),
    update: route("ws", path.workspaceRef, "members", path.userId),
  },
  invitation: {
    create: route("ws", path.workspaceRef, "invitations", "new"),
    view: route("ws", path.workspaceRef, "invitations", path.invitationId),
    list: route("ws", path.workspaceRef, "invitations").withQuery(
      query.workspaceInvitationList,
      { cache: true }
    ),
    code: {
      create: route("ws", path.workspaceRef, "invitation-codes", "new"),
      view: route(
        "ws",
        path.workspaceRef,
        "invitation-codes",
        path.invitationAlias
      ),
      list: route("ws", path.workspaceRef, "invitation-codes").withQuery(
        query.workspaceInvitationCodeList,
        { cache: true }
      ),
    },
  },
  catchAll: route("ws", path.workspaceRef, path.rest),
};

// prettier-ignore
export const admin = {
  home: route("admin"),
  workspace: {
    list: route("admin", "workspaces").withQuery(query.workspaceList, {
      cache: true,
    }),
    create: route("admin", "workspaces", "new"),
  },
  billing: {
    list: route("admin", "billing").withQuery(query.billingList, {
      cache: true,
    }),
  },
  member: {
    list: route("admin", "members").withQuery(query.adminMemberList, {
      cache: true,
    }),
    create: route("admin", "members", "new"),
    view: route("admin", "members", path.userId),
  },
  invitation: {
    create: route("admin", "invitations", "new"),
    list: route("admin", "invitations").withQuery(query.adminInvitationList, {
      cache: true,
    }),
    view: route("admin", "invitations", path.invitationId),
    code: {
      create: route("admin", "invitation-codes", "new"),
      view: route("admin", "invitation-codes", path.invitationAlias),
      list: route("admin", "invitation-codes").withQuery(
        query.adminInvitationCodeList,
        { cache: true }
      ),
    },
  },
  transfer: { user: route("admin", "transfer", "user") },
  rcaSetup: route("admin", "rca-setup"),
  catchAll: route("admin", path.rest),
};

export const catchAll = route(path.rest);
