import { Email, User } from "@cartographerio/types";
import { Box, VStack } from "@chakra-ui/react";
import { ReactElement } from "react";

import { useApiParams } from "../contexts/auth";
import { FrontContext } from "./context";
import { FrontUserItem } from "./FrontUserItem";
import { useFrontUsers } from "./useFrontUsers";

export interface FrontUserListProps {
  context: FrontContext;
  searchString?: string | null;
  emails?: Email[];
  onUserClick: (user: User) => void;
}

export default function FrontUserList(props: FrontUserListProps): ReactElement {
  const { context, searchString = null, emails = [], onUserClick } = props;

  const apiParams = useApiParams();
  const users = useFrontUsers(apiParams, searchString, emails);

  return (
    <VStack spacing="4" alignItems="stretch">
      {users.map(user => (
        <FrontUserItem
          key={user.id}
          context={context}
          user={user}
          onClick={onUserClick}
        />
      ))}
      {users.length === 0 && <Box>No users to display</Box>}
    </VStack>
  );
}
