import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";

import Spaced from "../components/Spaced";
import SuspenseBoundary from "../components/SuspenseBoundary";
import TextField from "../components/TextField";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { FrontContext, contextEmails } from "./context";
import { FrontDebug } from "./FrontDebug";
import FrontInvitationList from "./FrontInvitationList";
import FrontUsersTab from "./FrontUsersTab";
import FrontWorkspacesTab from "./FrontWorkspacesTab";

export interface FrontPluginProps {
  context: FrontContext;
}

export function FrontPlugin(props: FrontPluginProps): ReactElement {
  const { context } = props;

  const [searchString, setSearchString] = useState<string | null>(null);

  useEffect(() => {
    setSearchString(null);
  }, [context]);

  const frontEmails = useSuspenseQueryData({
    queryKey: ["front", "context", "emails", context.id],
    queryFn: () => contextEmails(context),
  });

  return (
    <Spaced spacing="2">
      <Box py="2" px="4">
        <TextField.NullableString
          value={searchString}
          onChange={setSearchString}
          placeholder="Search by name or email..."
          debounce={500}
        />
      </Box>

      <Tabs isLazy={true}>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Workspaces</Tab>
          <Tab>Invitations</Tab>
          <Tab>Debug</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SuspenseBoundary>
              <FrontUsersTab
                context={context}
                searchString={
                  (searchString?.length ?? 0) >= 3 ? searchString : undefined
                }
                emails={frontEmails}
              />
            </SuspenseBoundary>
          </TabPanel>
          <TabPanel>
            <SuspenseBoundary>
              <FrontWorkspacesTab
                context={context}
                searchString={
                  (searchString?.length ?? 0) >= 3 ? searchString : undefined
                }
                emails={frontEmails}
              />
            </SuspenseBoundary>
          </TabPanel>
          <TabPanel>
            <SuspenseBoundary>
              <FrontInvitationList
                context={context}
                searchString={searchString}
                emails={frontEmails}
              />
            </SuspenseBoundary>
          </TabPanel>
          <TabPanel>
            <SuspenseBoundary>
              <FrontDebug context={context} />
            </SuspenseBoundary>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Spaced>
  );
}
