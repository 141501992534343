import { WorkspaceBillingSummarySortKey } from "@cartographerio/client";
import { Option } from "@cartographerio/fp";
import {
  BillingTypeEnum,
  WorkspaceBillingSummary,
  ddmmyyyy,
  formatTimestamp,
  formatTimestampAgo,
} from "@cartographerio/types";
import { IoCard, IoHomeSharp, IoSettingsSharp } from "react-icons/io5";

import { formatIntervalAmount } from "../../billing/format";
import { invoiceTotalAmount } from "../../billing/helpers";
import { routes } from "../../routes";
import IconButtonLink from "../IconButtonLink";
import { ActionsColumn, Column } from "../SearchResultsList/column";

function col(
  params: Column<WorkspaceBillingSummary, WorkspaceBillingSummarySortKey>
) {
  return params;
}

const nameColumn = col({
  title: "Name",
  ellipsis: false,
  orderBy: "name",
  width: "auto",
  render: summary => summary.workspaceName,
});

const aliasColumn = col({
  title: "Alias",
  ellipsis: false,
  orderBy: "alias",
  width: "auto",
  render: summary => summary.workspaceAlias,
});

const billingTypeColumn = col({
  title: "Billing Type",
  ellipsis: false,
  orderBy: "billingtype",
  width: "auto",
  render: summary => BillingTypeEnum.labelOf(summary.settings.billingType),
});

const numProjectsColumn = col({
  title: "Projects",
  ellipsis: false,
  orderBy: "numprojects",
  width: "auto",
  render: summary => summary.usage.numProjects,
});

const numUsersColumn = col({
  title: "Users",
  ellipsis: false,
  orderBy: "numusers",
  width: "auto",
  render: summary => summary.usage.numUsers,
});

const trialEndColumn = col({
  title: "Trial End",
  ellipsis: false,
  orderBy: "trialend",
  width: "auto",
  render: summary =>
    Option.wrap(
      summary.type === "WorkspaceUnsubscribed"
        ? summary.settings.unsubscribedTrialEnd
        : summary.subscription.trialEnd
    )
      .map(ts => formatTimestamp(ts, { format: ddmmyyyy }))
      .getOrElse(() => "-"),
});

const lastSigninColumn = col({
  title: "Last Signin",
  ellipsis: false,
  orderBy: "trialend",
  width: "auto",
  render: summary =>
    Option.wrap(summary.usage.lastSignin)
      .map(formatTimestampAgo)
      .getOrElse(() => "-"),
});

const lastSurveyUpdateColumn = col({
  title: "Last Survey",
  ellipsis: false,
  orderBy: "trialend",
  width: "auto",
  render: summary =>
    Option.wrap(summary.usage.lastSurveyUpdate)
      .map(formatTimestampAgo)
      .getOrElse(() => "-"),
});

const planNameColumn = col({
  title: "Plan",
  ellipsis: false,
  orderBy: "plan",
  width: "auto",
  render: summary =>
    summary.type === "WorkspaceSubscribed" ? summary.plan.name : "-",
});

const intervalColumn = col({
  title: "Total",
  ellipsis: false,
  width: "auto",
  render: summary =>
    summary.type === "WorkspaceSubscribed"
      ? formatIntervalAmount(
          invoiceTotalAmount(summary.subscription.nextInvoiceTotal),
          summary.subscription.billingInterval
        )
      : "-",
});

export const workspaceColumns = [
  nameColumn,
  aliasColumn,
  billingTypeColumn,
  numProjectsColumn,
  numUsersColumn,
  trialEndColumn,
  lastSigninColumn,
  lastSurveyUpdateColumn,
  planNameColumn,
  intervalColumn,
];

export const workspaceActions: ActionsColumn<WorkspaceBillingSummary> = {
  renderButtons: summary => [
    summary.type === "WorkspaceSubscribed" ? (
      <IconButtonLink.External
        key="subscription"
        to={routes.workspace.billing.dashboard.url([summary.workspaceAlias])}
        variant="outline"
        label="Subscription"
        icon={<IoCard />}
        onClick={evt => evt.stopPropagation()}
      />
    ) : null,
    <IconButtonLink.Internal
      key="home"
      to={routes.workspace.home.url([summary.workspaceAlias])}
      variant="outline"
      label="Home"
      icon={<IoHomeSharp />}
      onClick={evt => evt.stopPropagation()}
    />,
    <IconButtonLink.Internal
      key="settings"
      to={routes.workspace.settings.url([summary.workspaceAlias])}
      variant="outline"
      label="Settings"
      icon={<IoSettingsSharp />}
      onClick={evt => evt.stopPropagation()}
    />,
  ],
};
