import {
  BillingInterval,
  PlanId,
  WorkspaceUnsubscribed,
  timestampToDate,
} from "@cartographerio/types";
import { useDisclosure } from "@chakra-ui/react";
import { compareAsc, formatDistance } from "date-fns";
import { useCallback, useMemo } from "react";

import Button from "../components/Button";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { routes } from "../routes";
import PlanChooserModal from "./PlanChooserModal";
import UsageCard from "./UsageCard";

export interface WorkspaceUnsubscribedViewProps {
  summary: WorkspaceUnsubscribed;
}

export default function WorkspaceUnsubscribedView(
  props: WorkspaceUnsubscribedViewProps
) {
  const { summary } = props;
  const { settings, usage } = summary;

  const {
    isOpen: planChooserOpen,
    onOpen: handlePlanChooserOpen,
    onClose: handlePlanChooserClose,
  } = useDisclosure();

  const planLink = useCallback(
    (plan: PlanId, interval: BillingInterval) =>
      routes.workspace.billing.checkout.url([summary.workspaceAlias], {
        plan,
        interval,
      }),
    [summary.workspaceAlias]
  );

  const daysLeft = useMemo(() => {
    if (settings.unsubscribedTrialEnd == null) {
      return null;
    } else {
      const now = new Date();
      const then = timestampToDate(settings.unsubscribedTrialEnd);
      const comp = compareAsc(now, then);
      return comp > 0 ? null : formatDistance(now, then);
    }
  }, [settings.unsubscribedTrialEnd]);

  return (
    <>
      <Spaced spacing="6">
        <Spaced spacing="1">
          <Para fontSize="large" textAlign="center">
            You are on a free trial
          </Para>

          {daysLeft != null && (
            <Para fontSize="lg" fontWeight="bold" textAlign="center">
              {daysLeft} left to choose a plan
            </Para>
          )}
        </Spaced>

        <UsageCard usage={usage} mx="auto" />

        <Para textAlign="center">
          {settings.unsubscribedTrialEnd == null
            ? "Choose a plan to continue using Cartographer"
            : "Choose a plan now and we'll start charging you when your trial ends."}
        </Para>

        <Para textAlign="center" mb="2">
          <Button label="Choose a Plan" onClick={handlePlanChooserOpen} />
        </Para>
      </Spaced>

      <PlanChooserModal
        workspaceRef={summary.workspaceId}
        defaultBillingInterval="Yearly"
        unsubscribedCouponId={settings.unsubscribedCouponId}
        isOpen={planChooserOpen}
        onClose={handlePlanChooserClose}
        planLink={planLink}
      />
    </>
  );
}
