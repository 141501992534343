import { ReactNode, useMemo } from "react";

import { BaseTabProps } from "./PageTabs";
import TabLink from "./TabLink";

interface PageTabProps extends BaseTabProps {
  to: string;
}

export default function PageTab(props: PageTabProps): ReactNode {
  const { id, label, to, isVisible } = props;

  const visible = useMemo(() => isVisible?.() ?? true, [isVisible]);
  return (
    visible && (
      <TabLink data-tab-id={id} to={to} mb={[2, "-2px", "-2px"]}>
        {label}
      </TabLink>
    )
  );
}
