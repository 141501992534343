import { endpoints } from "@cartographerio/client";
import { ApiParams, BannerMessage, WorkspaceRef } from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type WorkspaceCtaKey =
  | ["workspace", "cta"]
  | ["workspace", "cta", "v1", "search", WorkspaceRef];

export function workspaceCtaKey(key: WorkspaceCtaKey): WorkspaceCtaKey {
  return key;
}

export function list(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<BannerMessage[], WorkspaceCtaKey> {
  return {
    queryKey: ["workspace", "cta", "v1", "search", workspaceRef],
    queryFn: () =>
      endpoints.workspace.cta.v1
        .search(apiParams, workspaceRef)
        .map(search => search.results)
        .unsafeRun(),
  };
}
