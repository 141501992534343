import { checks } from "@cartographerio/permission";
import { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseInvitationCodeViewPage from "../base/BaseInvitationCodeViewPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceInvitationCodeViewPage(
  props: RouteProps<typeof routes.workspace.invitation.code.view>
): ReactElement {
  const {
    path: { workspaceRef, invitationAlias },
  } = props;

  const apiParams = useApiParams();
  const navigate = useNavigate();

  const workspaceGraph = useCurrentWorkspaceGraph();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const defaultCode = useSuspenseQueryData(
    queries.invitation.code.v3.readOrFail(apiParams, invitationAlias)
  );

  const codesEnabled = useCodesEnabled(workspace);

  useEffect(() => {
    if (!codesEnabled) {
      navigate(routes.workspace.home.url([workspace.alias]));
    } else if (
      defaultCode.workspaceId != null &&
      defaultCode.workspaceId !== workspace.id
    ) {
      navigate(routes.short.workspace.url([defaultCode.workspaceId]));
    }
  }, [
    codesEnabled,
    defaultCode.workspaceId,
    navigate,
    workspace.alias,
    workspace.id,
  ]);

  useRequirePermission(checks.workspace.admin(workspace.id));

  usePageTitle(
    useMemo(
      () => `${defaultCode.name || "Invitation Code"} - ${workspace.name}`,
      [defaultCode, workspace.name]
    )
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="invitation-code"
        invitationCode={defaultCode}
      />
      <WorkspacePageHeader workspace={workspace} selected="invitation-code" />
      <BaseInvitationCodeViewPage
        asTitle={false}
        backRedirect={routes.workspace.invitation.code.list.url([
          workspace.alias,
        ])}
        defaultCode={defaultCode}
        workspaceGraph={workspaceGraph}
        onDeleteGo={routes.workspace.invitation.code.list.url([
          workspace.alias,
        ])}
      />
    </>
  );
}
