import { Result } from "@cartographerio/fp";
import { InvitationCodeAlias } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Link as ChakraLink,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  VStack,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { RouteProps } from "../../../routes";
import { useIOErrorAlert } from "../../components/Alert";
import ButtonLink from "../../components/ButtonLink";
import CartographerLogo from "../../components/CartographerLogo";
import MenuHelp from "../../components/MenuHelp";
import Footer from "../../components/PageLayout/Footer";
import Para from "../../components/Para";
import TestTarget from "../../components/TestTarget";
import { useApiConfig, useApiServerId } from "../../contexts/apiConfig";
import {
  completeSigninAction,
  useAuthContext,
  useOptCredentials,
} from "../../contexts/auth";
import {
  ApiServerId,
  apiServerLabel,
  apiServerOptions,
} from "../../contexts/server";
import { useIOReducer } from "../../hooks/useIOReducer";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import { routes } from "../../routes";
import { reenterEmailAction, submitAction } from "./action";
import { initialSigninState } from "./helpers";
import { reducer } from "./reducer";
import { SigninForm } from "./SigninForm";
import { SignupForm } from "./SignupForm";

interface BaseSignInUpPageProps {
  go: string;
  isSignup: boolean;
  code?: InvitationCodeAlias;
}

function BaseSignInUpPage(props: BaseSignInUpPageProps): ReactElement {
  const { go, isSignup, code } = props;
  const navigate = useNavigate();

  useRedirectWhen(useOptCredentials() != null, () => go);

  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const [serverId, setServerId] = useApiServerId();

  console.log("serverId", serverId);

  const handleServerIdChange = useCallback(
    (id: ApiServerId) => {
      console.log("handleServerIdChange", id);
      setServerId?.(id);
    },
    [setServerId]
  );

  const [state, dispatch] = useIOReducer(reducer, initialSigninState(code));
  const { dispatch: authDispatch } = useAuthContext();

  useEffect(() => {
    if (
      state.type === "EnterPassword" &&
      state.credentials != null &&
      state.signin.code != null
    ) {
      switch (state.codeChallenge?.type) {
        case "InvitationCodeChallengeAlreadyUsed":
          toast({ status: "success", title: "Signed in" });
          break;
        case "InvitationCodeChallengeNotFound":
          toast({
            status: "warning",
            title: "Signed in without code",
            description: "The invitation code was invalid",
          });
          break;
        case "InvitationCodeChallengeCanceled":
          toast({
            status: "warning",
            title: "Signed in without code",
            description: "That invitation code has been canceled",
          });
          break;
        case "InvitationCodeChallengeExpired":
          toast({
            status: "warning",
            title: "Signed in without code",
            description: "That invitation code has expired",
          });
          break;
        case "InvitationCodeChallengeRolesUnchanged":
          toast({ status: "success", title: "Signed in" });
          break;
        case undefined:
          toast({
            status: "success",
            title: "Signed in",
            description: "You lso used an invitation code",
          });
          break;
        default:
          checkExhausted(state.codeChallenge);
      }
    }

    if (
      (state.type === "EnterPassword" || state.type === "Signup") &&
      state.credentials != null
    ) {
      authDispatch(completeSigninAction(Result.pass(state.credentials.token)));
      navigate(go, { replace: true });
    }
  }, [authDispatch, go, navigate, state, toast]);

  const { helpText, loadingText, continueButtonText } = useMemo((): {
    helpText: ReactElement;
    continueButtonText?: string;
    loadingText?: string;
  } => {
    switch (state.type) {
      case "EnterEmail":
        return {
          helpText: (
            <Para textAlign="center" fontSize="lg">
              Hello! Enter your email address
              {isSignup && " and invitation code"}.
            </Para>
          ),
          continueButtonText: "Continue",
          loadingText: "Checking Email",
        };
      case "EnterPassword":
        return {
          helpText: (
            <Para textAlign="center" fontSize="lg">
              Welcome back! Enter your password to sign in.
            </Para>
          ),
          continueButtonText: "Sign In",
          loadingText: "Signing In",
        };
      case "EnterCode":
        return {
          helpText: (
            <Para textAlign="center" fontSize="lg">
              Sorry - we couldn&apos;t find that email.
              <br />
              You can sign up for an account if you have an invitation code.
            </Para>
          ),
          continueButtonText: "Continue",
          loadingText: "Checking Code",
        };
      case "Signup":
        return {
          helpText: (
            <Para textAlign="center" fontSize="lg">
              It looks like you&apos;re new here. Welcome!
              <br />
              Fill out the form below to continue.
            </Para>
          ),
          continueButtonText: "Sign Up",
          loadingText: "Signing Up",
        };
      case "NeedsEmailVerification":
        return {
          helpText: (
            <Para textAlign="center" fontSize="lg">
              We&apos;ve sent you an email.
              <br />
              Click the link to setup a password and sign in.
            </Para>
          ),
        };
      default:
        return checkExhausted(state);
    }
  }, [isSignup, state]);

  const handleSubmit = useCallback(
    () => dispatch(submitAction(queryClient, apiConfig, state, errorAlert)),
    [apiConfig, dispatch, errorAlert, queryClient, state]
  );

  return (
    <Container
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <chakra.form onSubmit={evt => evt.preventDefault()}>
        <VStack spacing="8" maxW="40ch" mx="auto" my="8" align="center">
          <Box as="header">
            <CartographerLogo />
          </Box>

          {helpText}

          {state.type === "NeedsEmailVerification" ? (
            <ButtonLink.Internal to={routes.signin.url([])} variant="outline">
              Continue to Sign In
            </ButtonLink.Internal>
          ) : (
            <>
              <Card alignSelf="stretch">
                <CardBody px="8" py="8">
                  <Stack spacing="8">
                    {state.type === "Signup" ? (
                      <SignupForm state={state} dispatch={dispatch} />
                    ) : (
                      <SigninForm
                        state={state}
                        isSignup={isSignup}
                        dispatch={dispatch}
                      />
                    )}
                    <HStack justify="space-between">
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        colorScheme="blue"
                        isDisabled={state.loading}
                        isLoading={state.loading}
                        loadingText={loadingText}
                      >
                        {continueButtonText}
                      </Button>
                      {state.type !== "EnterEmail" && (
                        <Button
                          onClick={() => dispatch(reenterEmailAction)}
                          isDisabled={state.loading}
                          variant="outline"
                        >
                          Back
                        </Button>
                      )}
                    </HStack>
                  </Stack>
                </CardBody>
              </Card>

              <Menu placement="auto">
                <TestTarget testId="open-server-list">
                  <MenuButton as={ChakraLink} color="gray.400" tabIndex={0}>
                    {apiServerLabel(serverId)} Server <ChevronDownIcon />
                  </MenuButton>
                </TestTarget>
                <MenuList fontSize="sm" py="0">
                  <MenuHelp maxW="40ch">
                    Please select <strong>Production Server</strong> unless a
                    member of the Cartographer Support team has specifically
                    requested that you do otherwise.
                  </MenuHelp>
                  {apiServerOptions.map(opt => (
                    <TestTarget key={opt.id} testId={`${opt.id}-select-opt`}>
                      <MenuItem onClick={() => handleServerIdChange?.(opt.id)}>
                        {opt.label} Server
                      </MenuItem>
                    </TestTarget>
                  ))}
                </MenuList>
              </Menu>
            </>
          )}

          <chakra.hr w="50ch" mt="4" />
          <Footer pt="0" pb="4" />
        </VStack>
      </chakra.form>
    </Container>
  );
}

export function SigninPage(
  props: RouteProps<typeof routes.signin>
): ReactElement {
  const {
    query: { go = "/" },
  } = props;

  usePageTitle("Sign In");

  return <BaseSignInUpPage go={go} isSignup={false} />;
}

export function SigninWithCodePage(
  props: RouteProps<typeof routes.signinWithCode>
): ReactElement {
  const {
    path: { invitationCodeAlias: code },
    query: { go = "/" },
  } = props;

  usePageTitle("Sign In");

  return <BaseSignInUpPage go={go} code={code} isSignup={false} />;
}

export function SignupPage(
  props: RouteProps<typeof routes.signup>
): ReactElement {
  const {
    query: { go = "/", code },
  } = props;

  usePageTitle("Sign Up");

  return <BaseSignInUpPage go={go} code={code} isSignup={true} />;
}
