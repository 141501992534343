import { ApiParams, Email, User } from "@cartographerio/types";
import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";

import queries from "../../queries";
import { emptyArray } from "../../util";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";

export function useFrontUsers(
  apiParams: ApiParams,
  searchString: string | null,
  emails: Email[]
) {
  const searchResults =
    useSuspenseSearchResults(
      queries.optional(searchString, q =>
        queries.user.v2.search(apiParams, { q })
      )
    ) ?? emptyArray;

  const emailResults = useQueries({
    queries: emails
      .map(email => queries.user.v2.readOrNull(apiParams, email))
      .map(query => ({ ...query, suspense: true })),
  });

  return useMemo(() => {
    const x: User[] = searchResults;

    const y: User[] = emailResults.flatMap(result =>
      result.data == null ? [] : [result.data]
    );

    return [...x, ...y];
  }, [emailResults, searchResults]);
}
