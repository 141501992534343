import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { BannerMessage } from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { workspaceEmailSettingsRule } from "../../../schema/emailSettings";
import { workspaceRule } from "../../../schema/workspace";
import { useIOErrorAlert } from "../../components/Alert";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import WorkspaceEditor from "../../components/WorkspaceEditor";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import RecordMetadata from "./RecordMetadata";
import WorkspacePageHeader from "./WorkspacePageHeader";

function blankBanner(): BannerMessage {
  return { text: "" };
}

export default function WorkspaceSettingsPage(
  props: RouteProps<typeof routes.workspace.settings>
) {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const initialWorkspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const initialBanner =
    useSuspenseQueryData(
      queries.workspace.banner.v1.readOrNull(apiParams, initialWorkspace.id)
    ) ?? blankBanner();

  const initialEmailSettings = useSuspenseQueryData(
    queries.workspace.emailSettings.v1.readOrDefault(apiParams, workspaceRef)
  );

  useRequirePermission(checks.workspace.admin(initialWorkspace.id));

  const [workspace, setWorkspace] = useState(initialWorkspace);
  const [banner, setBanner] = useState(initialBanner);
  const [emailSettings, setEmailSettings] = useState(initialEmailSettings);

  usePageTitle(`Settings - ${workspace.name}`);

  const workspaceMessages = useMemo(
    () => workspaceRule(workspace),
    [workspace]
  );

  const bannerMessages = useMemo(() => [], []);

  const emailSettingsMessages = useMemo(
    () => workspaceEmailSettingsRule(emailSettings),
    [emailSettings]
  );

  const allMessages = useMemo(
    () =>
      workspaceMessages.concat(bannerMessages).concat(emailSettingsMessages),
    [bannerMessages, emailSettingsMessages, workspaceMessages]
  );

  const handleSave = useCallback(() => {
    queries.workspace.v2
      .save(queryClient, apiParams, workspace)
      .flatMap(IO.fromResult)
      .tap(({ alias }) => navigate(routes.workspace.settings.url([alias])))
      .tap(workspace =>
        IO.parForEach([
          queries.workspace.banner.v1.save(
            queryClient,
            apiParams,
            workspace.id,
            banner
          ),
          queries.workspace.emailSettings.v1.save(
            queryClient,
            apiParams,
            workspace.id,
            emailSettings
          ),
        ])
      )
      .tap(() => toast({ status: "success", description: "Settings Saved" }))
      .tapError(errorAlert)
      .unsafeRun();
  }, [
    apiParams,
    banner,
    emailSettings,
    errorAlert,
    navigate,
    queryClient,
    toast,
    workspace,
  ]);

  return (
    <>
      <PageTopBar workspace={initialWorkspace} workspacePage="settings" />
      <WorkspacePageHeader workspace={initialWorkspace} selected="settings" />
      <PageContainer width="narrow">
        <Spaced>
          <WorkspaceEditor
            workspace={workspace}
            onWorkspaceChange={setWorkspace}
            workspaceMessages={workspaceMessages}
            banner={banner}
            onBannerChange={setBanner}
            bannerMessages={bannerMessages}
            emailSettings={emailSettings}
            onEmailSettingsChange={setEmailSettings}
            emailSettingsMessages={emailSettingsMessages}
          />
          <SaveButton onClick={handleSave} messages={allMessages} />
          <RecordMetadata.Disclosure>
            <RecordMetadata.Item label="Workspace ID" value={workspace.id} />
          </RecordMetadata.Disclosure>
        </Spaced>
      </PageContainer>
    </>
  );
}
