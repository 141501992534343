import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import { usePermissionCheckPasses } from "../../hooks/usePermissionCheckPasses";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { routes } from "../../routes";
import BaseTeamListPage from "../base/BaseTeamListPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceTeamListPage(
  props: RouteProps<typeof routes.workspace.team.list>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const projects = useSuspenseSearchResults(
    queries.project.v2.forWorkspace(apiParams, workspace.id)
  );

  const canAddTeam = usePermissionCheckPasses(
    checks.team.create(workspace, projects)
  );

  useRequirePermissionRedirect(checks.workspace.admin(workspace.id), () =>
    routes.workspace.home.url([workspace.alias])
  );

  usePageTitle(`Teams - ${workspace.name}`);

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="teams" />
      <WorkspacePageHeader workspace={workspace} selected="teams" />
      <BaseTeamListPage
        workspace={workspace}
        enableAddButton={canAddTeam}
        query={query}
        updateQuery={updateQuery}
        enableProjectFilter={true}
        projects={projects}
      />
    </>
  );
}
