import {
  BillingInterval,
  PlanId,
  StripeCouponId,
  WorkspaceRef,
} from "@cartographerio/types";
import {
  Alert,
  AlertDescription,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import Spaced from "../components/Spaced";
import SuspenseBoundary from "../components/SuspenseBoundary";
import PlanChooser from "./PlanChooser";

interface PlanChooserModalProps {
  workspaceRef: WorkspaceRef;
  defaultPlanId?: PlanId;
  defaultBillingInterval?: BillingInterval;
  unsubscribedCouponId?: StripeCouponId | null;
  isOpen: boolean;
  onClose: () => void;
  planLink?: (planId: PlanId, billingInterval: BillingInterval) => string;
  onPlanClick?: (planId: PlanId, billingInterval: BillingInterval) => void;
}

export default function PlanChooserModal(
  props: PlanChooserModalProps
): ReactElement {
  const {
    workspaceRef,
    defaultPlanId,
    defaultBillingInterval,
    unsubscribedCouponId,
    isOpen,
    onClose,
    planLink,
    onPlanClick,
  } = props;

  const handlePlanClick = useCallback(
    (planId: PlanId, billingInterval: BillingInterval) => {
      onClose();
      onPlanClick?.(planId, billingInterval);
    },
    [onClose, onPlanClick]
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="3xl" marginInline="6">
        <ModalHeader>Choose a Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="0" pb="0">
          <SuspenseBoundary>
            <Spaced>
              {unsubscribedCouponId != null && (
                <Alert status="info" justifyContent="center">
                  <AlertDescription textAlign="center">
                    A discount will be applied after you choose your plan and
                    shown on the checkout page.
                  </AlertDescription>
                </Alert>
              )}
              <PlanChooser
                workspaceRef={workspaceRef}
                defaultPlanId={defaultPlanId}
                defaultBillingInterval={defaultBillingInterval}
                planLink={planLink}
                onPlanClick={handlePlanClick}
              />
            </Spaced>
          </SuspenseBoundary>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
