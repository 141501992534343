import { checks } from "@cartographerio/permission";
import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";

import { routes } from "../../routes";
import RequirePermission from "../RequirePermission";
import SidebarHeader from "./SidebarHeader";
import { SidebarMenu } from "./SidebarMenu";
import SidebarMenuItem from "./SidebarMenuItem";
import SidebarSection from "./SidebarSection";

export default function AdminSidebar(): ReactElement {
  return (
    <Box>
      <SidebarHeader
        title="Cartographer Admin"
        linkTo={routes.admin.home.url([])}
      />

      <SidebarSection title="Global Admin">
        <SidebarMenu>
          <SidebarMenuItem
            linkTo={routes.admin.workspace.list.url([])}
            addLink={routes.admin.workspace.create.url([])}
            text="Workspaces"
          />
          <RequirePermission check={checks.billing.global}>
            <SidebarMenuItem
              linkTo={routes.admin.billing.list.url([])}
              addLink={routes.admin.workspace.create.url([])}
              text="Billing"
            />
          </RequirePermission>
          <SidebarMenuItem
            linkTo={routes.admin.member.list.url([])}
            addLink={routes.admin.member.create.url([])}
            text="Members"
          />
          <SidebarMenuItem
            linkTo={routes.admin.invitation.list.url([])}
            addLink={routes.admin.invitation.create.url([])}
            text="Invitations"
          />
          <SidebarMenuItem
            linkTo={routes.admin.invitation.code.list.url([])}
            addLink={routes.admin.invitation.code.create.url([])}
            text="Invitation Codes"
          />
        </SidebarMenu>
      </SidebarSection>

      <SidebarSection title="Tools">
        <SidebarMenu>
          <SidebarMenuItem
            linkTo={routes.admin.transfer.user.url([])}
            text="Transfer User"
          />
          <SidebarMenuItem
            linkTo={routes.admin.rcaSetup.url([])}
            text="RCA Trainees Setup"
          />
        </SidebarMenu>
      </SidebarSection>
    </Box>
  );
}
