import { Box, TabList, Tabs, TabsProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import useFindNestedChildren from "../hooks/useFindNestedChildren";
import PageTab from "./PageTab";
import PageTabSelect from "./PageTabSelect";

export interface BaseTabProps {
  id: string;
  label: string;
  isVisible?: () => boolean;
}

interface PageTabsProps extends Omit<TabsProps, "children"> {
  selected: string;
  children: ReactElement<BaseTabProps, typeof PageTab | typeof PageTabSelect>[];
}

export default function PageTabs(props: PageTabsProps): ReactElement {
  const { selected, children, ...rest } = props;

  // Children can contain RequirePermission, Fragments, and other elements
  // So I'm needing to "flatten" them and go from there
  const allTabs = useFindNestedChildren(children, [
    PageTab,
    PageTabSelect,
  ]) as ReactElement<BaseTabProps>[];

  const index = useMemo(() => {
    const [primaryTab] = selected.split("-");
    const index = allTabs
      .filter(tab => tab.props.isVisible?.() ?? true)
      .findIndex(child => child.props.id === primaryTab);
    return index >= 0 ? index : undefined;
  }, [selected, allTabs]);

  const selectedTab = useMemo(() => tabOptionId([selected]), [selected]);

  return (
    <Box __css={{ [`.${selectedTab}`]: { bg: "gray.100" } }}>
      <Tabs
        defaultIndex={index}
        index={index}
        variant={["soft-rounded", "line", "line"]}
        w="100%"
        isManual={true}
        data-selected={selectedTab}
        {...rest}
      >
        <TabList display="flex" flexWrap="wrap" borderBottomWidth={[2, 2, 2]}>
          {children}
        </TabList>
      </Tabs>
    </Box>
  );
}

export function tabOptionId(path: string[]): string {
  return `tab-${path.join("-")}`;
}
