import { Email } from "@cartographerio/types";
import { VStack } from "@chakra-ui/react";
import { ReactElement } from "react";

import { useApiParams } from "../contexts/auth";
import LoadingSpinner from "../form/AttachmentField/LoadingSpinner";
import { FrontContext } from "./context";
import { FrontWorkspaceItem } from "./FrontWorkspaceItem";
import { useFrontWorkspaces } from "./userFrontWorkspaces";

export interface FrontWorkspacesTabProps {
  context: FrontContext;
  searchString?: string | null;
  emails?: Email[];
}

export default function FrontWorkspacesTab(
  props: FrontWorkspacesTabProps
): ReactElement {
  const { context, searchString = null, emails = [] } = props;

  const apiParams = useApiParams();
  const workspaces = useFrontWorkspaces(apiParams, searchString, emails);

  return workspaces == null ? (
    <LoadingSpinner />
  ) : (
    <VStack alignItems="full">
      {workspaces.map(workspace => (
        <FrontWorkspaceItem
          key={workspace.id}
          context={context}
          workspace={workspace}
        />
      ))}
    </VStack>
  );
}
