import { checks } from "@cartographerio/permission";
import {
  Workspace,
  WorkspaceBillingSettings,
  WorkspaceBillingSummary,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, Flex, HStack, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import BillingDisabled from "../../billing/BillingDisabled";
import CheckoutSuccessPanel from "../../billing/CheckoutSuccessPanel";
import WorkspaceBillingSettingsEditor from "../../billing/WorkspaceBillingSettingsEditor";
import WorkspaceSubscribedView from "../../billing/WorkspaceSubscribedView";
import WorkspaceUnsubscribedView from "../../billing/WorkspaceUnsubscribedView";
import AdminContent from "../../components/AdminContent";
import Disclosure from "../../components/Disclosure";
import JsonView from "../../components/JsonView";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import { StripeCheckoutResult } from "../../routes/queryParams";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceBillingSettingsPage(
  props: RouteProps<typeof routes.workspace.billing.settings>
): ReactElement {
  const {
    path: { workspaceRef },
    query: { checkout },
    updateQuery,
  } = props;
  updateQuery;

  const queryClient = useQueryClient();
  const apiParams = useApiParams();
  const toast = useToast();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const summary = useSuspenseQueryData(
    queries.billing.summary.v1.readOrFail(apiParams, workspaceRef)
  );

  const clearQuery = useCallback(() => {
    updateQuery({ checkout: null }, { replace: true });
  }, [updateQuery]);

  useEffectOnce(() => {
    if (checkout === "success") {
      toast({
        title: "Checkout successful",
        status: "success",
        isClosable: true,
      });
    } else if (checkout === "cancel") {
      toast({
        title: "Checkout cancelled",
        status: "warning",
        isClosable: true,
      });

      clearQuery();
    }
  });

  useRequirePermission(checks.billing.workspace(workspace.id));
  usePageTitle(`Billing - ${workspace.name}`);

  const onSettingsChange = useCallback(
    (updates: WorkspaceBillingSettings) => {
      queries.billing.settings.v1
        .update(queryClient, apiParams, workspace.id, updates)
        .tap(() => toast({ colorScheme: "green", title: "Settings saved" }))
        .tapError(() =>
          toast({ colorScheme: "red", title: "Could not save settings" })
        )
        .unsafeRun();
    },
    [apiParams, queryClient, toast, workspace.id]
  );

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="billing" />
      <WorkspacePageHeader workspace={workspace} selected="billing" />
      <PageContainer width="default">
        <HStack align="stretch">
          <Box flexGrow={1}>
            <MainSummaryPanel
              workspace={workspace}
              summary={summary}
              checkout={checkout}
              clearQuery={clearQuery}
            />
          </Box>

          <Flex grow={0}>
            <AdminContent>
              <WorkspaceBillingSettingsEditor
                value={summary.settings}
                onChange={onSettingsChange}
              />
            </AdminContent>
          </Flex>
        </HStack>
        <AdminContent mt="8">
          <Disclosure toggle="Billing summary data">
            <JsonView value={summary} />
          </Disclosure>
        </AdminContent>
      </PageContainer>
    </>
  );
}

interface MainSummaryPanelProps {
  workspace: Workspace;
  summary: WorkspaceBillingSummary;
  checkout: StripeCheckoutResult | null;
  clearQuery: () => void;
}

function MainSummaryPanel(props: MainSummaryPanelProps) {
  const { workspace, summary, checkout, clearQuery } = props;

  if (!summary.settings.enableBilling) {
    return <BillingDisabled />;
  } else {
    switch (summary.type) {
      case "WorkspaceUnsubscribed":
        return summary.settings.billingType === "Free" ? (
          <BillingDisabled />
        ) : checkout === "success" ? (
          <CheckoutSuccessPanel workspace={workspace} clearQuery={clearQuery} />
        ) : (
          <WorkspaceUnsubscribedView summary={summary} />
        );

      case "WorkspaceSubscribed":
        return <WorkspaceSubscribedView summary={summary} />;

      default:
        return checkExhausted(summary);
    }
  }
}
