import {
  ApiParams,
  Email,
  WorkspaceId,
  roleWorkspaceId,
} from "@cartographerio/types";
import { useQueries } from "@tanstack/react-query";
import { chain } from "lodash";
import { useMemo } from "react";

import queries from "../../queries";
import { useFrontUsers } from "./useFrontUsers";

export function useFrontWorkspaces(
  apiParams: ApiParams,
  searchString: string | null,
  emails: Email[]
) {
  const users = useFrontUsers(apiParams, searchString, emails);

  const workspaceIds: WorkspaceId[] = useMemo(
    () =>
      chain(users)
        .flatMap(user => user.roles)
        .map(roleWorkspaceId)
        .filter((id: WorkspaceId | null): id is WorkspaceId => id != null)
        .uniq()
        .value(),
    [users]
  );

  const workspaceResults = useQueries({
    queries: workspaceIds
      .map(id => queries.workspace.v2.readOrNull(apiParams, id))
      .map(query => ({ ...query, suspense: true })),
  });

  return useMemo(
    () =>
      workspaceResults.flatMap(result =>
        result.data == null ? [] : [result.data]
      ),
    [workspaceResults]
  );
}
