import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  QualificationRegisterEntry,
  emptySearchResults,
} from "@cartographerio/types";
import {
  FormControl,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import Button from "../../components/Button";
import CartographerLogo from "../../components/CartographerLogo";
import Container from "../../components/Container";
import FormLabel from "../../components/FormLabel";
import Heading from "../../components/Heading";
import Link from "../../components/Link";
import PageContainer from "../../components/PageContainer";
import Para from "../../components/Para";
import SearchField from "../../components/SearchField";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import {
  formatEntryEmail,
  formatEntryLevel,
  formatEntryName,
} from "./QualificationCertificatePage";

export default function QualificationRegisterPage(
  props: RouteProps<typeof routes.qualification.register>
): ReactElement {
  const {
    path: { qualificationAlias },
    query: { q },
    updateQuery,
  } = props;

  const navigate = useNavigate();

  const apiParams = useApiParams();
  const [searching, setSearching] = useState(false);

  const qualification = useSuspenseQueryData(
    queries.qualification.v1.readOrFail(apiParams, qualificationAlias)
  );

  const [entries, setEntries] = useState<QualificationRegisterEntry[]>([]);

  const onSearch = useCallback(
    () =>
      IO.wrap(() => setSearching(true))
        .andThen(
          q != null && q.trim().length > 0
            ? endpoints.qualification.register.v1
                .qualificationSearch(apiParams, qualificationAlias, { q })
                .sleepUpTo(500)
            : IO.pure(emptySearchResults<QualificationRegisterEntry>())
        )
        .tap(({ results }) => setEntries(results))
        .cleanup(() => setSearching(false))
        .unsafeRun(),
    [apiParams, qualificationAlias, q]
  );

  useEffectOnce(() => {
    onSearch();
  });

  const handleClick = useCallback(
    (entry: QualificationRegisterEntry) => {
      navigate(routes.qualification.certificate.url([entry.id]));
    },
    [navigate]
  );

  return (
    <PageContainer>
      <chakra.form
        onSubmit={evt => {
          evt.preventDefault();
          onSearch();
        }}
        display="flex"
        flexDir="column"
        alignItems="center"
        mb="4"
      >
        <Spaced spacing="8" py="16">
          <CartographerLogo />
          <Heading level="section" w="fit-content">
            {qualification.name}
          </Heading>
          <FormControl>
            <FormLabel text="Search the Surveyor Register" />
            <HStack w="100%">
              <SearchField
                defaultValue={q ?? ""}
                onChange={q => updateQuery({ q: q ?? undefined })}
                placeholder="Search by name or email"
              />
              <Button
                type="submit"
                label={searching ? "Searching" : "Search"}
                colorScheme="blue"
                disabled={searching}
                isLoading={searching}
                w="10ch"
              />
            </HStack>
          </FormControl>
        </Spaced>
      </chakra.form>

      {entries.length > 0 && (
        <Table w="100%" bg="white" rounded="lg">
          <Tbody>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Level</Th>
            </Tr>
            {entries.map(entry =>
              entry.lastUpdate.roleName == null ? null : (
                <Tr
                  key={entry.id}
                  onClick={() => handleClick(entry)}
                  cursor="pointer"
                >
                  <Td>
                    <Link.Internal
                      to={routes.qualification.certificate.url([entry.id])}
                    >
                      {formatEntryName(entry, "public")}
                    </Link.Internal>
                  </Td>
                  <Td>{formatEntryEmail(entry, "public") ?? "-"}</Td>
                  <Td>{formatEntryLevel(entry)}</Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      )}

      <Container width="narrow">
        <Para color="gray.400" fontSize="sm" textAlign="center" mt="12">
          Some qualified surveyors may not have opted in to this public
          register. If your search returns no results, please check
          qualification status directly with the surveyor.
        </Para>
      </Container>
    </PageContainer>
  );
}
