import { WorkspaceSortKey } from "@cartographerio/client";
import {
  KnownWorkspaceFeature,
  KnownWorkspaceFeatureEnum,
  Workspace,
} from "@cartographerio/types";
import { HStack, Icon, IconButton } from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  IoCloudOfflineSharp,
  IoGlobeOutline,
  IoHelp,
  IoHomeSharp,
  IoPeopleOutline,
  IoQrCodeOutline,
  IoSettingsSharp,
} from "react-icons/io5";

import { routes } from "../../routes";
import Link from "../Link";
import { ActionsColumn, Column } from "../SearchResultsList/column";

function col(params: Column<Workspace, WorkspaceSortKey>) {
  return params;
}

const nameColumn = col({
  title: "Name",
  ellipsis: false,
  orderBy: "name",
  width: "auto",
  render: workspace => workspace.name,
});

const aliasColumn = col({
  title: "Alias",
  ellipsis: false,
  orderBy: "alias",
  width: "auto",
  render: function WorkspaceSlug(workspace) {
    return workspace.alias;
  },
});

const operatorColumn = col({
  title: "Operator",
  ellipsis: true,
  width: "auto",
  render: function WorkspaceOperator(workspace) {
    if (workspace.operator == null) {
      return "-";
    } else if (workspace.homepage == null) {
      return workspace.operator;
    } else {
      return (
        <Link.External to={workspace.homepage}>
          {workspace.operator}
        </Link.External>
      );
    }
  },
});

// const privacyPolicyColumn = col({
//   title: "Privacy Policy",
//   ellipsis: true,
//   width: "auto",
//   render: function WorkspacePrivacyPolicy(workspace) {
//     if (workspace.privacyPolicy == null) {
//       return null;
//     } else {
//       return (
//         <Link to={workspace.privacyPolicy}>{workspace.privacyPolicy}</Link>
//       );
//     }
//   },
// });

export function featuresColumn<A, B extends string, O extends string>(
  getFeatures: (a: A) => B[],
  featureLabel: (b: B) => string,
  featureIcon: (b: B) => IconType | null
): Column<A, O> {
  return {
    title: "Features",
    width: "16ch",
    render: function Features(document: A) {
      return getFeatures(document).length === 0 ? (
        "-"
      ) : (
        <HStack spacing="1">
          {getFeatures(document)
            .sort()
            .map(feature => (
              // HACK: This is an <a> tag because we're in a <LinkBox> and we want the element to be hoverable.
              <a
                key={feature}
                href="#"
                onClick={evt => evt.preventDefault()}
                title={featureLabel(feature)}
              >
                <Icon
                  zIndex={1000}
                  as={featureIcon(feature) ?? IoHelp}
                  color="gray.500"
                />
              </a>
            ))}
        </HStack>
      );
    },
  };
}

const workspaceFeaturesColumn = featuresColumn<
  Workspace,
  KnownWorkspaceFeature,
  WorkspaceSortKey
>(
  ws => ws.features.filter(KnownWorkspaceFeatureEnum.isValue),
  KnownWorkspaceFeatureEnum.labelOf,
  feature => {
    switch (feature) {
      case KnownWorkspaceFeatureEnum.EnableArcgisIntegration:
        return IoGlobeOutline;
      case KnownWorkspaceFeatureEnum.EnableInvitationCodes:
        return IoQrCodeOutline;
      case KnownWorkspaceFeatureEnum.EnableTeams:
        return IoPeopleOutline;
      case KnownWorkspaceFeatureEnum.OfflineMaps:
        return IoCloudOfflineSharp;
      default:
        return null;
    }
  }
);

export function workspaceColumns(showFeatures: boolean) {
  return [
    nameColumn,
    aliasColumn,
    operatorColumn,
    // privacyPolicyColumn,
    ...(showFeatures ? [workspaceFeaturesColumn] : []),
  ];
}

export const workspaceActions: ActionsColumn<Workspace> = {
  renderButtons: workspace => [
    <IconButton
      key="home"
      as={Link.Internal}
      to={routes.workspace.home.url([workspace.alias])}
      variant="outline"
      aria-label="Home"
      icon={<IoHomeSharp />}
      onClick={evt => evt.stopPropagation()}
    />,
    <IconButton
      key="settings"
      as={Link.Internal}
      to={routes.workspace.settings.url([workspace.alias])}
      variant="outline"
      aria-label="Settings"
      icon={<IoSettingsSharp />}
      onClick={evt => evt.stopPropagation()}
    />,
  ],
};
