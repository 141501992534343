import { checks } from "@cartographerio/permission";
import { globalAdminRole, workspaceActiveRole } from "@cartographerio/types";
import { Box } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { Navigate } from "react-router-dom";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import CartographerLogo from "../components/CartographerLogo";
import PageContainer from "../components/PageContainer";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import WorkspaceSelector from "../components/WorkspaceSelector";
import { useApiParams, useCredentials } from "../contexts/auth";
import { usePageTitle } from "../hooks/usePageTitle";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { routes } from "../routes";

export default function HomePage(
  _props: RouteProps<typeof routes.home>
): ReactElement {
  const apiParams = useApiParams();
  const credentials = useCredentials();

  const access = useSuspenseSearchResults(
    queries.auth.v2.listWorkspaceAccess(apiParams)
  );

  usePageTitle("Select Workspace");

  const permissionCheckPasses = usePermissionCheckRunner();

  const autoRedirect = useMemo(
    () =>
      access.length === 1 &&
      access[0].type === "WorkspaceAccessGranted" &&
      permissionCheckPasses(
        checks.or(
          checks.auth.hasRoles([globalAdminRole]),
          checks.auth.hasRoles([workspaceActiveRole(access[0].id)])
        )
      ),
    [access, permissionCheckPasses]
  );

  return autoRedirect ? (
    <Navigate to={routes.workspace.home.url([access[0].alias])} />
  ) : (
    <PageContainer>
      <Spaced spacing="8" maxW="50ch" mx="auto" my="16">
        <Box as="header">
          <CartographerLogo mb="8" />
        </Box>

        <Para textAlign="center" fontSize="lg">
          Welcome, <strong>{credentials.identity.screenName}</strong>! Please
          choose a workspace:
        </Para>

        <WorkspaceSelector options={access} showOptionsByDefault={true} />
      </Spaced>
    </PageContainer>
  );
}
