import { isArray } from "@cartographerio/guard";
import { checks } from "@cartographerio/permission";
import { Team, Workspace } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import Container from "../../components/Container";
import {
  InvitationTabOption,
  PageBreadcrumbProps,
} from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import PageTab from "../../components/PageTab";
import PageTabs from "../../components/PageTabs";
import PageTabSelect, { pageTabOption } from "../../components/PageTabSelect";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import { routes } from "../../routes";

export interface TeamPageHeaderProps {
  workspace: Workspace;
  team: Team;
  selected: Exclude<PageBreadcrumbProps["teamPage"], undefined>;
}

export default function TeamPageHeader(
  props: TeamPageHeaderProps
): ReactElement {
  const { workspace, team, selected } = props;

  const selectedTab = useMemo(
    () => (isArray(selected) ? selected[0] : selected),
    [selected]
  );
  const permissionCheckPasses = usePermissionCheckRunner();

  const codesEnabled = useCodesEnabled(workspace);

  return (
    <Container sticky={true} width="wide">
      <PageHeader title={`${team.name} (Team)`}>
        <PageTabs selected={selectedTab}>
          <PageTab
            id="settings"
            label="Settings"
            to={routes.workspace.team.settings.url([
              workspace.alias,
              team.alias,
            ])}
            isVisible={() =>
              permissionCheckPasses(checks.team.viewSettings(team))
            }
          />
          <PageTab
            id="members"
            label="Members"
            to={routes.workspace.team.member.list.url([
              workspace.alias,
              team.alias,
            ])}
            isVisible={() =>
              permissionCheckPasses(checks.team.viewMembers(team))
            }
          />
          <PageTabSelect<InvitationTabOption>
            id="invitation"
            label="Invitations"
            isVisible={() =>
              permissionCheckPasses(checks.team.viewInvitations(team))
            }
            options={[
              pageTabOption({
                id: "single",
                label: "Invitations",
                to: routes.workspace.team.invitation.list.url([
                  workspace.alias,
                  team.alias,
                ]),
              }),
              ...(codesEnabled
                ? [
                    pageTabOption({
                      id: "code",
                      label: "Invitation Codes",
                      to: routes.workspace.team.invitation.code.list.url([
                        workspace.alias,
                        team.alias,
                      ]),
                    }),
                  ]
                : []),
            ]}
          />
        </PageTabs>
      </PageHeader>
    </Container>
  );
}
