import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { Message } from "@cartographerio/topo-core";
import {
  ProjectEmailSettings,
  ProjectId,
  ProjectMapSettings,
  Team,
  WorkspaceId,
  randomTeamId,
  unsafeTeamAlias,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { projectEmailSettingsRule } from "../../../schema/emailSettings";
import { rule } from "../../../schema/rule";
import { teamRule } from "../../../schema/team";
import { useIOErrorAlert } from "../../components/Alert";
import BackButtonHeading from "../../components/BackButtonHeading";
import Container from "../../components/Container";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import TeamEditor from "../../components/TeamEditor";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { routes } from "../../routes";
import WorkspacePageHeader from "./WorkspacePageHeader";

export function blankTeam(
  workspaceId: WorkspaceId,
  projectId?: ProjectId
): Team {
  return {
    id: randomTeamId(),
    workspaceId,
    projectIds: projectId != null ? [projectId] : [],
    name: "",
    alias: unsafeTeamAlias(""),
    features: [],
  };
}

export default function WorkspaceTeamCreatePage(
  props: RouteProps<typeof routes.workspace.team.create>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;

  const { project: projectRef } = query;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();
  const toast = useToast();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const project = useSuspenseQueryData(
    queries.optional(projectRef, projectRef =>
      queries.project.v2.readOrFail(apiParams, projectRef, workspace.id)
    )
  );

  useEffect(() => {
    if (project != null && project.workspaceId !== workspace.id) {
      updateQuery({ ...query, project: undefined });
    }
  }, [project, query, updateQuery, workspace.id]);

  useRequirePermissionRedirect(checks.workspace.admin(workspace.id), () =>
    routes.workspace.home.url([workspace.alias])
  );

  usePageTitle(`New Team - ${workspace.name}`);

  const permissionCheckPasses = usePermissionCheckRunner();

  const [team, setTeam] = useState(() => blankTeam(workspace.id, project?.id));

  const teamMessages = useMemo(() => teamRule(team), [team]);

  const projectOptions = useSuspenseSearchResults(
    queries.project.v2.forWorkspace(apiParams, workspace.id),
    projects =>
      projects.map(project => ({
        label: project.name,
        value: project.id,
        disabled: !permissionCheckPasses(
          checks.team.associate(team, project.id)
        ),
      }))
  );

  const defaultEmailSettings =
    useSuspenseQueryData(
      queries.optional(project?.id, projectId =>
        queries.project.emailSettings.v1.readOrDefault(apiParams, projectId)
      )
    ) ?? queries.project.emailSettings.v1.defaultEmailSettings;

  const defaultMapSettings =
    useSuspenseQueryData(
      queries.optional(project?.id, projectId =>
        queries.project.mapSettings.v1.readOrDefault(apiParams, projectId)
      )
    ) ?? queries.project.mapSettings.v1.defaultMapSettings;

  const [emailSettings, setEmailSettings] =
    useState<ProjectEmailSettings | null>(null);

  const emailSettingsMessages = useMemo(
    () => rule.nullable(projectEmailSettingsRule)(emailSettings),
    [emailSettings]
  );

  const [mapSettings, setMapSettings] =
    useState<ProjectMapSettings | null>(null);

  const mapSettingsMessages = useMemo<Message[]>(() => [], []);

  const allMessages = useMemo(
    () => [...teamMessages, ...emailSettingsMessages, ...mapSettingsMessages],
    [emailSettingsMessages, mapSettingsMessages, teamMessages]
  );

  const handleSave = useCallback(() => {
    if (teamMessages.length === 0) {
      queries.team.v2
        .save(queryClient, apiParams, team)
        .flatMap(IO.fromResult)
        .tap(team =>
          IO.parForEach([
            queries.team.emailSettings.v2.save(
              queryClient,
              apiParams,
              team.id,
              emailSettings
            ),
            queries.team.mapSettings.v2.save(
              queryClient,
              apiParams,
              team.id,
              mapSettings
            ),
          ])
        )
        .tap(() => toast({ status: "success", description: "Team created!" }))
        .tap(() => navigate(routes.workspace.team.list.url([workspace.alias])))
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [
    teamMessages.length,
    queryClient,
    apiParams,
    team,
    errorAlert,
    emailSettings,
    mapSettings,
    toast,
    navigate,
    workspace.alias,
  ]);

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="teams" team="new" />
      <WorkspacePageHeader workspace={workspace} selected="teams" />
      <Container width="wide">
        <Spaced spacing="4">
          <BackButtonHeading
            to={routes.workspace.team.list.url([workspace.alias])}
          >
            New Team
          </BackButtonHeading>
          <TeamEditor
            team={team}
            onTeamChange={setTeam}
            projectOptions={projectOptions}
            teamMessages={teamMessages}
            emailSettings={emailSettings}
            defaultEmailSettings={defaultEmailSettings}
            onEmailSettingsChange={setEmailSettings}
            emailSettingsMessages={emailSettingsMessages}
            mapSettings={mapSettings}
            defaultMapSettings={defaultMapSettings}
            onMapSettingsChange={setMapSettings}
            mapSettingsMessages={mapSettingsMessages}
          />
          <SaveButton onClick={handleSave} messages={allMessages} />
        </Spaced>
      </Container>
    </>
  );
}
